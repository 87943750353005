import React from 'react'
import Layout from "../components/theme/layout"
import { Link } from "gatsby"
import SEO from "../components/theme/seo"
// import Instagram from "../components/instagram"

import { Container, Row, Col, Tab, ListGroup, Breadcrumb } from 'react-bootstrap'

export default function Detailing() {
    return (
        <Layout>
        <SEO title="Detailing Packages" />
            <div className="masthead white detailing">
                <Container>
                    <Row><h1>Detailing Packages</h1>
                    <Breadcrumb>
                        <Breadcrumb.Item href="/services">Services</Breadcrumb.Item>
                        <Breadcrumb.Item active>Detailing</Breadcrumb.Item>
                    </Breadcrumb>
                    </Row>
                </Container>
            </div>
            <Container fluid>
            <Tab.Container id="list-group-tabs-example" defaultActiveKey="#basic-interior">
            <Row className="mobile-reverse">
              <Col sm={4} className="section-toggle">
                <ListGroup>
                  <ListGroup.Item action href="#basic-interior">
                    Basic Interior Package
                  </ListGroup.Item>
                  <ListGroup.Item action href="#basic-exterior">
                    Basic Exterior Wash
                  </ListGroup.Item>

                  <ListGroup.Item action href="#bravo-interior">
                      The Bravo-1 Interior
                  </ListGroup.Item>
                  <ListGroup.Item action href="#bravo-exterior">
                      The Bravo-1 Exterior
                  </ListGroup.Item>
                  <ListGroup.Item action href="#bravo-signature">
                      The Bravo-1 Signature Package
                  </ListGroup.Item>
                  <ListGroup.Item action href="#generals-choice">
                      The Generals Choice Package
                  </ListGroup.Item>
                  <ListGroup.Item action href="#engine-detail">
                      Engine Bay Detailing
                  </ListGroup.Item>
                  <ListGroup.Item action href="#headlight-restoration">
                      Headlight Restoration
                  </ListGroup.Item>
                  <ListGroup.Item action href="#add-ons">
                      Add-Ons
                  </ListGroup.Item>
                  </ListGroup>
              </Col>
              <Col sm={8} className="package-contents">
                <Tab.Content>
                  <Tab.Pane eventKey="#basic-interior">
                    <h2>Basic Interior Package</h2>
                    <h3 className="gold"><small>Duration: 1-2 Hours</small></h3>
                    <ul>
                        <li>Thorough Vac</li>
                        <li>Wipedown</li>
                        <li>Cup Holders Cleaned</li>
                        <li>Rubber Mats Cleaned</li>
                        <li>Windows &amp; Mirrors Cleaned</li>
                    </ul>
                    <Row>
                        <div className="pricing-block">
                            <h3>Small Vehicles</h3>
                            <h4>From<br/>$85</h4>
                        </div>
                        <div className="pricing-block">
                            <h3>Mid-Size Vehicles</h3>
                            <h4>From<br/>$95</h4>
                        </div>
                        <div className="pricing-block">
                            <h3>Large Vehicles</h3>
                            <h4>From<br/>$110</h4>
                        </div>
                        <div className="pricing-block">
                            <h3>XL Vehicles</h3>
                            <h4>From<br/>$125</h4>
                        </div>
                    </Row>
                  </Tab.Pane>
                  <Tab.Pane eventKey="#basic-exterior">
                    <h2>Basic Exterior Wash</h2>
                    <h3 className="gold"><small>Duration: 1-2 Hours</small></h3>
                    <ul>
                        <li>Thorough Rinse</li>
                        <li>Foam Bath</li>
                        <li>Hand Wash</li>
                        <li>Hand Dried</li>
                        <li>Spray Wax Applied</li>
                        <li>Tires &amp; Rims thoroughly cleaned and tires dressed</li>
                        <li>door jambs wiped</li>
                        <li>rubber mats cleaned</li>
                        <li>windows and mirrors cleaned</li>
                        </ul>
                    <Row>
                        <div className="pricing-block">
                            <h3>Bikes</h3>
                            <h4>From<br/>$50</h4>
                        </div>
                        <div className="pricing-block">
                            <h3>Small Vehicles</h3>
                            <h4>From<br/>$75</h4>
                        </div>
                        <div className="pricing-block">
                            <h3>Mid-Size Vehicles</h3>
                            <h4>From<br/>$85</h4>
                        </div>
                        <div className="pricing-block">
                            <h3>Large Vehicles</h3>
                            <h4>From<br/>$95</h4>
                        </div>
                        <div className="pricing-block">
                            <h3>XL Vehicles</h3>
                            <h4>From<br/>$105</h4>
                        </div>
                    </Row>
                  </Tab.Pane>
                  <Tab.Pane eventKey="#bravo-interior">
                    <h2>The Bravo-1 Interior Detail</h2>
                    <h3 className="gold"><small>Duration: 4-5 Hours</small></h3>
                    <p>Your vehicle will receive a thorough vacuum throughout the entire interior and all of the carpets and upholstery are shampooed, steam cleaned and extracted using our professional grade deep cleaning heated extractor. The headliner is spot treated, the petals and rubber mats are cleaned, all salt build up is removed off of the floor, and any leather is cleaned and conditioned to bring back the shine, luster, and to protect it from cracking. The vents are steamed out and disinfected, all of the plastic and vinyl is cleaned and dressed using our non greasy dressing, and the cup holders, centre console, and glove box are cleaned as well. We also try our best to remove any markings and stains, and we also get into all of the cracks and crevices.  The inner windows and mirrors are cleaned, and air freshener is misted throughout for that new car smell! This package will have your vehicles interior looking better than the day you picked it up.</p>
                    <ul>
                        <li>Complete Interior Shampooing &amp; Steaming</li>
                        <li>Full Thorough Vac</li>
                        <li>Full thorough wipedown</li>
                        <li>Dressing applied</li>
                        <li>Leather treatment applied</li>
                        <li>Cup Holders Cleaned</li>
                        <li>Rubber Mats Cleaned</li>
                        <li>Windows &amp; Mirrors Cleaned</li>
                        <li>All cracks &amp; crevices cleaned</li>
                        <li>Full Disinfecting</li>
                        <li>All scuffs &amp; Markings Removed</li>
                    </ul>
                    <Row>
                        <div className="pricing-block">
                            <h3>Small Vehicles</h3>
                            <h4>From<br/>$200</h4>
                        </div>
                        <div className="pricing-block">
                            <h3>Mid-size Vehicles</h3>
                            <h4>From<br/>$225</h4>
                        </div>
                        <div className="pricing-block">
                            <h3>Large Vehicles</h3>
                            <h4>From<br/>$250</h4>
                        </div>
                        <div className="pricing-block">
                            <h3>XL Vehicles</h3>
                            <h4>From<br/>$275</h4>
                        </div>
                    </Row>
                  </Tab.Pane>

                  <Tab.Pane eventKey="#bravo-exterior">
                  <h2>The Bravo-1 Exterior Detail</h2>
                  <h3 className="gold"><small>Duration: 2-3 Hours</small></h3>
                  <p>Your vehicle will receive a thorough rinse followed by a foam bath and a thorough 2 bucket hand wash removing any bugs, bird droppings and tar. We will then clay bar the vehicle, dry by hand/air, and apply our high tech carnauba paste wax using an orbital polisher. The tires are scrubbed clean, the rims are deep cleaned using soft brushes and are also waxed by hand, and the wheel wells are cleaned and dressed. Any plastic trim is dressed, all of the windows and mirrors are cleaned, and our hydrophobic windshield coating is then applied. Jambs are wiped and chrome exhaust tips are cleaned, polished, and protected.</p>
                    <ul>
                        <li>Thorough Rinse</li>
                        <li>Foam Bath</li>
                        <li>Hand Wash</li>
                        <li>Hand Dried</li>
                        <li>Clay bar</li>
                        <li>Carnauba Wax Applied</li>
                        <li>Tires &amp; Rims thoroughly cleaned and tires dressed</li>
                        <li>door jambs wiped</li>
                        <li>rubber mats cleaned</li>
                        <li>windows and mirrors cleaned</li>
                        <li>Windshield Coating Applied</li>
                        <li>Rims Waxed</li>
                        <li>Chrome Tips Polished</li>
                        <li>Trim Dressing Applied</li>
                        </ul>
                    <Row>
                        <div className="pricing-block">
                            <h3>Small Vehicles</h3>
                            <h4>From<br/>$225</h4>
                        </div>
                        <div className="pricing-block">
                            <h3>Mid-size Vehicles</h3>
                            <h4>From<br/>$250</h4>
                        </div>
                        <div className="pricing-block">
                            <h3>Large Vehicles</h3>
                            <h4>From<br/>$275</h4>
                        </div>
                        <div className="pricing-block">
                            <h3>XL Vehicles</h3>
                            <h4>From<br/>$300</h4>
                        </div>
                        <div className="pricing-block">
                            <h3>Bikes</h3>
                            <h4>From<br/>$125</h4>
                        </div>
                        <p>**Bikes will recieve the following: Light rinse, bug and tar removal, thorough hand wash, hand and air dried, clay bar treatment, carnauba wax applied by hand, leather cleaned and treated, windshield and mirrors cleaned, hydrophobic windshield coating added, chrome exhaust polished</p>
                    </Row>
                  </Tab.Pane>

                  <Tab.Pane eventKey="#bravo-signature">
                  <h2>The Bravo-1 Signature Package</h2>
                  <h3 className="gold"><small>This is our most popular package! Duration: 7-8 Hours</small></h3>
                  <p>Your vehicle will receive the Bravo-1 Interior Detail package and the exterior will receive a thorough rinse followed by a foam bath and a thorough 2 bucket hand wash which will remove any bugs, bird droppings and tar. The entire paint is clay barred, the tires are scrubbed clean, the rims are deep cleaned using soft brushes, and the wheel wells are cleaned and dressed. Carnauba wax is then applied using an orbital polisher and all windows and mirrors are cleaned, including jambs. Any chrome exhaust tips are polished and protected, and all plastic trim is dressed. We will need most of the day with your vehicle for this meticulous package! See coatings section for other coating options you may add!</p>
                  <ul>
                    <li>Thorough Rinse</li>
                    <li>Foam Bath</li>
                    <li>Hand Wash</li>
                    <li>Hand Dried</li>
                    <li>Carnauba Wax</li>
                    <li>Clay Bar</li>
                    <li>Tires &amp; Rims thoroughly cleaned and tires dressed</li>
                    <li>door jambs wiped</li>
                    <li>rubber mats cleaned</li>
                    <li>windows and mirrors cleaned</li>
                    <li>Thorough Vac</li>
                    <li>Wipedown</li>
                    <li>Cup Holders Cleaned</li>
                    <li>Rubber Mats Cleaned</li>
                    <li>Windows &amp; Mirrors Cleaned</li>
                  </ul>
                  <Row>
                        <div className="pricing-block">
                            <h3>Small Vehicles</h3>
                            <h4>From<br/>$350</h4>
                        </div>
                        <div className="pricing-block">
                            <h3>Mid-size Vehicles</h3>
                            <h4>From<br/>$375</h4>
                        </div>
                        <div className="pricing-block">
                            <h3>Large Vehicles</h3>
                            <h4>From<br/>$400</h4>
                        </div>
                        <div className="pricing-block">
                            <h3>XL Vehicles</h3>
                            <h4>From<br/>$425</h4>
                        </div>
                    </Row>
                  </Tab.Pane>

                  <Tab.Pane eventKey="#generals-choice">
                  <h2>The Generals Choice Package</h2>
                  <h3 className="gold"><small>Complete Restoration Package. Duration: 8-10 Hours</small></h3>
                  <p>This package includes everything from our Bravo-1 interior and Bravo-1 exterior package as well as an engine bay detail.</p>
                  <ul>
                    <li>Thorough Rinse</li>
                    <li>Foam Bath</li>
                    <li>Hand Wash</li>
                    <li>Hand Dried</li>
                    <li>Clay bar</li>
                    <li>Carnauba Wax Applied</li>
                    <li>Tires &amp; Rims thoroughly cleaned and tires dressed</li>
                    <li>door jambs wiped</li>
                    <li>rubber mats cleaned</li>
                    <li>windows and mirrors cleaned</li>
                    <li>Windshield Coating Applied</li>
                    <li>Rims Waxed</li>
                    <li>Chrome Tips Polished</li>
                    <li>Trim Dressing Applied</li>
                    <li>Complete Interior Shampooing &amp; Steaming</li>
                    <li>Full Thorough Vac</li>
                    <li>Full thorough wipedown</li>
                    <li>Dressing applied</li>
                    <li>Leather treatment applied</li>
                    <li>Cup Holders Cleaned</li>
                    <li>Rubber Mats Cleaned</li>
                    <li>Windows &amp; Mirrors Cleaned</li>
                    <li>All cracks &amp; crevices cleaned</li>
                    <li>Full Disinfecting</li>
                    <li>All scuffs &amp; Markings Removed</li>
                  </ul>
                  <Row>
                        <div className="pricing-block">
                            <h3>Small Vehicles</h3>
                            <h4>From<br/>$575</h4>
                        </div>
                        <div className="pricing-block">
                            <h3>Mid-size Vehicles</h3>
                            <h4>From<br/>$600</h4>
                        </div>
                        <div className="pricing-block">
                            <h3>Large Vehicles</h3>
                            <h4>From<br/>$625</h4>
                        </div>
                        <div className="pricing-block">
                            <h3>XL Vehicles</h3>
                            <h4>From<br/>$650</h4>
                        </div>
                    </Row>
                  </Tab.Pane>

                  <Tab.Pane eventKey="#engine-detail">
                    <h2>Engine Bay Detailing</h2>
                    <h3 className="gold"><small>Duration: 1-2 Hours</small></h3>
                    <p>Depending on condition, we will do a thorough light rinse and degrease followed by steam to remove any stuck on dirt and grime. Any chrome pieces are polished, jambs are cleaned, the under hood is cleaned, and all plastics are dressed.</p>
                    <Row>
                        <div className="pricing-block">
                            <h3>Engine only</h3>
                            <h4>From<br/>$80</h4>
                        </div>
                        <div className="pricing-block">
                            <h3>As an add-on to a package</h3>
                            <h4>From<br/>$50</h4>
                        </div>
                    </Row>
                  </Tab.Pane>

                  <Tab.Pane eventKey="#headlight-restoration">
                  <h2>Headlight Restoration</h2>
                  <h3 className="gold"><small>Duration: 1-2 Hours</small></h3>
                  <p>Your headlights will receive a cleaning followed by a 3-4 stage wet sand and finished with a 2 step polish to ensure a crystal clear finish.  We will then  apply 2 coats of ceramic to each headlight to ensure full protection and longevity. We will then clay bar and prep the headlights and apply two coats of ceramic to each. Includes 1 year warranty.</p>
                  <Row>
                      <div className="pricing-block">
                          <h3>Headlights only</h3>
                          <h4>From<br/>$100</h4>
                      </div>
                  </Row>
                  </Tab.Pane>
                  <Tab.Pane eventKey="#add-ons">
                  <h2>Add-ons</h2>
                  <Row>
                      <div className="pricing-block">
                          <h3>Pet hair removal</h3>
                          <h4>From: $50</h4>
                      </div>
                      <div className="pricing-block">
                          <h3>Pressure washing services</h3>
                          <h4><Link to="contact">Contact</Link></h4>
                      </div>
                  </Row>
                  </Tab.Pane>
                </Tab.Content>
              </Col>
            </Row>
          </Tab.Container>
          </Container>
            </Layout>
    )
}


